<template>
  <div>
    <!-- <h2 class="text-center">{{ modelAnamnese.nome }}</h2> -->
    <!-- {{ modelAnamnese }} -->
    <!-- {{horario}} -->


    <b-tabs>
      <b-tab title="Formulário Virtual" class="p-2">
        <div>
          <div class="row justify-content-center" v-if="!viewOnly">
            <div class="col-12 col-sm-10">
              <b-form-group >
                <label>Nome</label>
                <b-form-input readonly :value="paciente.nome"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6 col-sm-2 mb-0 pb-0">
              <b-form-group>
                <label>Idade</label>
                <b-form-input
                  class="text-center  "
                  readonly
                  :value="paciente.idade"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <small
                class=""
                v-if="responsavel && responsavel.nome && responsavel.data"
                >Preenchido por {{ responsavel.nome }}, na data
                {{ responsavel.data | moment("DD/MM/YY") }}, às
                {{ responsavel.data | moment("HH:mm") }}</small
              >
            </div>
          </div>

          <div class="">
            <div
              v-for="questionario of modelAnamnese.questionario"
              :key="modelAnamnese.questionario.indexOf(questionario)"
              class="p-2  mt-1 "
            >
              <!-- {{questionario}} -->
              <div class="  border shadow p-2 " v-if="questionario.tipo == 1">
                <div class="">
                  <label class="mr-2"> {{ questionario.pergunta }}</label>
                </div>
                <div class="text-center mb-2 ">
                  <b-form-radio-group
                    class="border rounded p-1"
                    :options="questionario.opcoes"
                    :disabled="(jaPreenchido && !editando)||viewOnly"
                    v-model="questionario.resposta"
                  ></b-form-radio-group>
                </div>

                <div class="  mt-3 mt-sm-0" v-if="questionario.obs">
                  <b-form-group>
                    <label> Observações</label>
                    <b-form-textarea
                      v-model="questionario.observacao"
                      :readonly="jaPreenchido && !editando"
                      rows="1"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <!-- <div class="col-12">
          <hr />
        </div> -->
              </div>

              <div class=" border shadow p-2" v-if="questionario.tipo == 2">
                <div class="mb-2">
                  <label class="mr-2 "> {{ questionario.pergunta }}</label>
                  <b-form-input
                    :disabled="(jaPreenchido && !editando)||viewOnly"
                    v-model="questionario.resposta"
                  ></b-form-input>
                </div>

                <div class="" v-if="questionario.obs">
                  <b-form-group>
                    <label> Observações</label>
                    <b-form-textarea
                      :disabled="(jaPreenchido && !editando)||viewOnly"
                      v-model="questionario.observacao"
                      rows="1"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <!-- <div class="col-12">
          <hr />
        </div> -->
              </div>

              <div class=" border shadow p-2 " v-if="questionario.tipo == 3">
                <div class="mb-2">
                  <label class="mr-2"> {{ questionario.pergunta }}</label>
                  <b-form-input
                    :disabled="(jaPreenchido && !editando)||viewOnly"
                    v-model="questionario.resposta"
                    type="number"
                  ></b-form-input>
                </div>

                <div class="mt-3 mt-sm-0" v-if="questionario.obs">
                  <b-form-group>
                    <label> Observações</label>
                    <b-form-textarea
                      :disabled="(jaPreenchido && !editando)||viewOnly"
                      v-model="questionario.observacao"
                      rows="1"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <!-- <div class="col-12">
          <hr />
        </div> -->
              </div>

              <div class=" border shadow p-2" v-if="questionario.tipo == 4">
                <div class="mb-2">
                  <label class="mr-2"> {{ questionario.pergunta }}</label>
                  <b-form-textarea
                    v-model="questionario.resposta"
                    type="number"
                    :disabled="(jaPreenchido && !editando)||viewOnly"
                  ></b-form-textarea>
                </div>

                <div class=" mt-3 mt-sm-0" v-if="questionario.obs">
                  <b-form-group>
                    <label> Observações</label>
                    <b-form-textarea
                      v-model="questionario.observacao"
                      rows="1"
                      :disabled="(jaPreenchido && !editando)||viewOnly"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <!-- <div class="col-12">
          <hr>
        </div> -->
              </div>

              <div
                class=" border shadow p-2 justify-content-center"
                v-if="questionario.tipo == 5"
              >
                <div class="">
                  <label class="mr-2"> {{ questionario.pergunta }}</label>
                </div>

                <div class="text-center">
                  <b-form-checkbox-group
                    class="border rounded p-1"
                    :disabled="(jaPreenchido && !editando)||viewOnly"
                    :options="questionario.opcoes"
                    v-model="questionario.resposta"
                  ></b-form-checkbox-group>
                </div>

                <div class="  mt-3 mt-sm-0" v-if="questionario.obs">
                  <b-form-group>
                    <label> Observações</label>
                    <b-form-textarea
                      v-model="questionario.observacao"
                      rows="1"
                      size="sm"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <!-- <div class="col-12">
          <hr>
        </div> -->
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab title="Formulário em Folha" class="p-2">
        <div>
          <div>
            <ol>
              <li
                v-for="questionario of modelAnamnese.questionario"
                :key="modelAnamnese.questionario.indexOf(questionario)"
                class="p-2  mt-1 "
              >
                <div>
                  {{ questionario.pergunta }}
                  <div
                    class="d-inline-block"
                    v-if="questionario.tipo == 2 || questionario.tipo == 3"
                  >
                    <p  style="text-decoration: underline;">{{questionario.resposta?questionario.resposta+'________________________________________________________________________':' __________________________________________________________________________________________'}}</p>
                  </div>
                  <div v-if="questionario.tipo == 4">
                    <p v-if="questionario.resposta"  style="text-decoration: underline;">{{questionario.resposta?questionario.resposta+'________________________________________________________________________':' __________________________________________________________________________________________'}}</p>
          
                    <p v-else v-for="line of 5" :key="line">
                      _____________________________________________________________________________________________________
                    </p>
                  </div>
                </div>
                <ol style="list-style-type: none;" v-if="questionario.opcoes">
                
                  <li v-for="opcao of questionario.opcoes" :key="opcao">
                    <div v-if="questionario.tipo == 1">( {{questionario.resposta&&questionario.resposta.includes(opcao)?'X':''}} ) {{ opcao }}</div>
                    <div v-if="questionario.tipo == 5">[ {{questionario.resposta&&questionario.resposta.includes(opcao)?'X':''}} ] {{ opcao }}</div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div class="d-flex w-100  border-top pt-3 mt-4" v-if="!viewOnly">
      <div class="mr-auto">
        <b-btn
          v-if="jaPreenchido"
          :variant="editando ? 'info' : 'warning'"
          @click="editar"
        >
          <i :class="[`fas fa-${editando ? 'undo' : 'edit'}`]"></i>
          {{ editando ? "Desfazer" : "Editar" }}</b-btn
        >
      </div>
      <b-btn
        class="mr-3"
        variant="primary"
        :disabled="(jaPreenchido && !editando)||viewOnly"
        @click="salvar"
      >
        <i class="fas fa-save"></i> Salvar</b-btn
      >
      <b-btn class="" variant="secondary" @click="close">
        <i class="fas fa-ban"></i> Cancelar</b-btn
      >
    </div>
  </div>
</template>

<script>
import AnamneseLib from "../../../libs/AnamneseLib";
//import _ from 'lodash'

export default {
  props: {
    id: [Number, String],
    paciente: {
      type: Object,
      default: () => {
        return { nome: "", idade: "" };
      },
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    questionarioPreenchido: { type: Object, default: () => {} },
    responsavel: { type: Object, default: () => {} },
    preVisualizar: [Object],
  },
  data() {
    return {
      modelAnamnese: {},
      jaPreenchido: false,
      editando: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async carregarModelo() {
      await this.$nextTick();
      this.$forceUpdate();
      this.modelAnamnese = (await AnamneseLib.get(this.id))[0];
      //  console.log('ANAMNESE',this.modelAnamnese);
      if (this.modelAnamnese) {
        for (let questionario of this.modelAnamnese.questionario) {
          if (questionario) {
            this.$set(
              questionario,
              "resposta",
              questionario.tipo == 5 ? [] : questionario.tipo == 3 ? 0 : ""
            );
            if (questionario.obs) this.$set(questionario, "observacao", "");
          }
        }
      }
    },
    salvar() {
      const anamnese = Object.assign({}, this.modelAnamnese);
      // delete anamnese.empresa_id
      // delete anamnese.status
      this.$emit("salvou", anamnese);
    },
    editar() {
      if (this.editando) {
        this.modelAnamnese = JSON.parse(
          JSON.stringify(this.questionarioPreenchido)
        );
      }
      this.editando = !this.editando;
    },
  },
  async mounted() {
    //console.log(this.questionarioPreenchido);
    //console.log(this.id);
    this.$forceUpdate();
    await this.$nextTick();
    if (this.preVisualizar) {
      this.modelAnamnese = JSON.parse(JSON.stringify(this.preVisualizar));

      return;
    }
    if (this.questionarioPreenchido && this.questionarioPreenchido.id) {
      this.modelAnamnese = JSON.parse(
        JSON.stringify(this.questionarioPreenchido)
      );

      // //console.time('com lodash')
      // this.modelAnamnese = _.cloneDeep(this.questionarioPreenchido)
      // //console.timeEnd('com lodash')
      this.jaPreenchido = true;
      return;
      //console.log("aaaaaaaaaaaaaaa", this.modelAnamnese);
    }

    if (
      this.id &&
      !(!!this.questionarioPreenchido && !!this.questionarioPreenchido.id)
    ) {
      await this.carregarModelo();
      return;
    }
    
 
    //console.log("aaaaaaaaaaaaaaa", this.modelAnamnese);
  },
};
</script>

<style></style>
