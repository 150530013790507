<template>
  <div>
    <div class="row">
      <div class="col-6">
        <b-form-group>
          <label>Nome</label>
          <b-form-input
            placeholder="Nome"
            v-model="anamnese.nome"
            :state="validateState({ campo: 'nome' })"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group>
          <label>Status</label>
          <b-form-checkbox
            class="form-control text-center"
            switch
            :unchecked-value="0"
            :value="1"
            v-model="anamnese.status"
            >{{ anamnese.status ? "Ativo" : "Inativo" }}</b-form-checkbox
          >
        </b-form-group>
      </div>
    </div>

    <div>
      <b-table
        :fields="campos"
        :items="anamnese.questionario"
        responsive="sm"
        small
        striped
        :busy="loading"
        stacked="sm"
      >
        <template #cell(pergunta)="row">
          <b-form-input
            size="sm"
            v-model="row.item.pergunta"
            :state="validateState({ campo: 'pergunta', index: row.index })"
          ></b-form-input>
        </template>

        <template #cell(tipo)="row">
          <b-form-select
            size="sm"
            v-model="row.item.tipo"
            :state="validateState({ campo: 'tipo', index: row.index })"
            :options="tiposDeResposta"
            @change="onTipoChange(row.index)"
          ></b-form-select>
        </template>

        <template #cell(opcoes)="row">
          <b-form-tags
            tag-variant="primary"
            tag-pills
            v-model="row.item.opcoes"
            :disabled="!(row.item.tipo == 1 || row.item.tipo == 5)"
            remove-on-delete
            separator=","
            size="sm"
            :state="validateState({ campo: 'opcoes', index: row.index })"
            placeholder="Adicione opções (Sim, Não, Talvez...)"
          ></b-form-tags>
        </template>

        <template #cell(obs)="row">
          <b-form-checkbox v-model="row.item.obs" switch size="sm">
          </b-form-checkbox>
        </template>

        <template #cell(actions)="row">
          <b-btn variant="transparent" @click="removePergunta(row.index)">
            <i class="fas fa-times"></i
          ></b-btn>
        </template>
      </b-table>
      <div class="row">
        <div class="col d-flex justify-content-end">
          <b-btn variant="primary" @click="addPergunta">
            <i class="fas fa-plus"></i
          ></b-btn>
        </div>
      </div>
    </div>

    <b-modal id="modal-visualizacao" hide-footer  size="lg">
      <anamnese-listagem-folha :preVisualizar="anamnese" />
    </b-modal>
  </div>
</template>

<script>
import Validadores from "../../helpers/Validadores";
import AnamneseLib from "../../libs/AnamneseLib";
import AnamneseListagemFolha from "./Listagem/AnamneseListagemFolha.vue";
export default {
  components:{
    AnamneseListagemFolha
  },
  props: {
    anamnese_edit: Object,
  },
  data() {
    return {
      anamnese: {
        nome: "",
        questionario: [
          {
            pergunta: "",
            tipo: 0,
            opcoes: [],
            obs: false,
          },
        ],
        status: 1,
      },
      hasErrors: [],
      tiposDeResposta: [
        { text: "Opção Única", value: 1 },
        { text: "Texto", value: 2 },
        { text: "Número", value: 3 },
        { text: "Texto Longo", value: 4 },
        { text: "Seleção Multipla", value: 5 },
      ],
      campos: [
        { key: "pergunta", label: "Pergunta", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "opcoes", label: "Opções", class: "text-center" },
        { key: "obs", label: "Observação", class: "text-center" },
        { key: "actions", label: "", class: "text-center" },
      ],
      loading: false,
    };
  },
  mounted() {
    if (!isNaN(this.anamnese_edit.id)) {
      Object.assign(this.anamnese, this.anamnese_edit);
    }
  },
  watch: {
    anamnese: {
      deep: true,
      handler(newVal) {
        newVal.questionario.forEach((_, i) => {
          this.validar(i);
        });
      },
    },
  },
  methods: {
    addPergunta() {
      this.anamnese.questionario.push({
        pergunta: "",
        tipo: 0,
        opcoes: [],
        obs: false,
      });
    },
       preVisualizar(){
      this.$bvModal.show('modal-visualizacao')
    },
    removePergunta(index) {
      this.anamnese.questionario.splice(index, 1);
    },
    validateState(campo) {
      // console.log('validateState',campo)
      let hasError = this.hasErrors.find(
        (x) => x.campo.campo == campo.campo && x.campo.index == campo.index
      );
      //console.log('hasErrors', this.hasErrors)
      // console.log('hasErrir', hasError)
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      //console.log(campo)
      let hasError = this.hasErrors.find(
        (x) => x.campo.campo == campo.campo && x.campo.index === campo.index
      );
      // console.log('hasErrors', this.hasErrors)
      if (!hasError) {
        //console.log('vai puxar: ', {campo, state});
        this.hasErrors.push({ campo, state });
        //console.log(this.hasErrors)
      } else {
        hasError.state = state;
      }
      // console.log('hasErrors DEPOIS', this.hasErrors)
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    onTipoChange(index) {
      this.anamnese.questionario[index].opcoes = [];
      this.anamnese.questionario.forEach((_, i) => {
        this.validar(i);
      });
    },
    validar(index = "") {
      //   console.log("try validate", `index [${index}]`);
      //   console.log(this.anamnese.questionario[index]);
      this.clearErrors();
      this.$forceUpdate();

      let ignorar = 0;

      this.addOrUpdateError(
        { campo: "nome" },
        !Validadores.string.IsNullOrEmpty(this.anamnese.nome)
      );

      //console.log(`pergunta[${index}]`,     this.anamnese.questionario[index].pergunta)
      this.addOrUpdateError(
        { campo: "pergunta", index },
        !Validadores.string.IsNullOrEmpty(
          this.anamnese.questionario[index].pergunta
        )
      );
      // console.log(`hasError? `,this.hasErrors.find(
      //   (x) => x.campo.campo == 'pergunta' && x.campo.index === index))
      this.addOrUpdateError(
        { campo: "tipo", index },
        !Validadores.string.IsNullOrEmpty(
          this.anamnese.questionario[index].tipo
        )
      );
      if (
        this.anamnese.questionario[index].tipo &&
        (this.anamnese.questionario[index].tipo == 1 ||
          this.anamnese.questionario[index].tipo == 5)
      ) {
        this.addOrUpdateError(
          { campo: "opcoes", index },
          this.anamnese.questionario[index].opcoes.length > 0
        );
      } else {
        ignorar++;
      }

      //console.log(this.hasErrors.filter((x) => x.state == true).length);
      return (
        this.hasErrors.filter((x) => x.state == true).length >= 4 - ignorar
      );
    },
    async salvar() {
      let validou = this.anamnese.questionario
        .map((_, i) => {
          return this.validar(i);
        })
        .some((e) => e == false);

      if (validou) {
        this.showToast("error", "Por favor, verifique os dados.");
        return;
      }

      try {
        let result = await AnamneseLib.store(this.anamnese);
        if (result.status == 200) {
          this.showToast("success", "Anamnese salvo com successo!");
          this.$emit("salvou");
        }
      } catch (error) {
        this.showToast("error", error.message);
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
