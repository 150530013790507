<template>
  <div>
    <b-table
      :fields="campos"
      :items="anameses"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      show-empty
      :busy="loading"
    >
     <template #empty>
      <div>
       <i class="fas fa-notes-medical"></i> Nenhum modelo de anamnese cadastrada, clique em <b-btn @click="$emit('novo')" variant="primary" size="sm" class="mx-2"> <i class="fas fa-plus"></i> Anamnese</b-btn> para cadastrar. 
      </div>
    </template>
      <template #cell(status)="row">
        <b-form-checkbox
          @change="onStatusChange(row.item)"
          v-model="row.item.status"
          :unchecked-value="0"
          :value="1"
          switch
        ></b-form-checkbox>
      </template>
      <template #cell(actions)="row">
        <b-btn
          variant="warning"
          size="sm"
          class="mr-3"
          @click="editar(row.item)"
        >
          <i class="fas fa-edit"></i> Editar</b-btn
        >
        <b-btn variant="danger" size="sm">
          <i class="fas fa-trash"></i> Excluir</b-btn
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import AnamneseLib from "../../libs/AnamneseLib";

export default {
  data() {
    return {
      anameses: [],
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "status", label: "Status", class: "text-center" },
        { key: "actions", label: "", class: "text-right" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      loading: false,
    };
  },
  methods: {
    async carregar() {
      try {
        this.anameses = await AnamneseLib.get();
      } catch (error) {
        this.showToast("error", `Erro ao carregar anamneses ${error.message}`);
      }
    },
    async onStatusChange(row) {
      try {
        this.loading = true;
        await AnamneseLib.store(row);
      } catch (error) {
        this.showToast("error", error.message);
      } finally {
        this.loading = false;
      }
    },
    editar(anamnese) {
      this.$emit("editar", anamnese);
    },
  },
  mounted() {
    this.carregar();
  },
};
</script>

<style></style>
