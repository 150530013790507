import axios from "../utils/axios";

const get = async (id) => {
  let result = await axios
    .get("anamneses", { params: { id } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });

  return result;
};

const store = async (data) => {
  let result = await axios
    .post("anamneses", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // console.log('err anamnese', err.response.data);
      throw err.response.data.error;
    });
  return result;
};

const del = async (data) => {
  let result = await axios
    .delete(`anamneses/${data.id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // console.log('err anamnese', err);
      throw err;
    });
  return result;
};

export default {
  get,
  store,
  del,
};
