<template>
  <div class="container-fluid">
    <BarraCrud :onFiltrar="filtrar" :botaoNovo="true" :onNovo="onNovo" />
    <AnamneseTable @novo="onNovo"  ref="AnamneseTable" @editar="editar($event)" />

    <b-modal id="ModalAnamneseForm" no-fade content-class="modalDireito">
      <template #modal-title>
        <i class="fas fa-notes-medical"></i>
        {{
          !isNaN(anamnese_edit.id) ? "Editando" : "Adicionando"
        }}
        Anamnese</template
      >
      <AnamneseForm
        ref="AnamneseForm"
        @salvou="carregar"
        :anamnese_edit="anamnese_edit"
      />
      <template #modal-footer="{ cancel }">
        <b-btn variant="info" @click="$refs.AnamneseForm.preVisualizar()" class="mr-auto"
          > <i class="fas fa-eye"></i> Pré-visualizar</b-btn
        >

        <b-btn variant="primary" @click="$refs.AnamneseForm.salvar()">
          <i class="fas fa-save"></i> Salvar</b-btn
        >

        <b-btn variant="warning" @click="cancel()">
          <i class="fas fa-ban"> </i> Cancelar</b-btn
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import AnamneseTable from "../../components/Anamnese/AnamneseTable.vue";
import AnamneseForm from "../../components/Anamnese/AnamneseForm.vue";
import BarraCrud from "@/components/common/BarraCrud.vue";

export default {
  data() {
    return {
      filtro: "",
      anamnese_edit: null,
     
    };
  },
  components: {
    BarraCrud,
    AnamneseTable,
    AnamneseForm,
  },
  methods: {
    filtrar(filtro) {
      this.filtro = filtro;
    },
    onNovo() {
      this.limpar();
      this.$bvModal.show("ModalAnamneseForm");
    },
 
    limpar() {
      this.anamnese_edit = {
        nome: "",
        questionario: [
          {
            pergunta: "",
            tipo: 0,
            opcoes: [],
            obs: false,
          },
        ],
      };
    },
    carregar() {
      this.$bvModal.hide("ModalAnamneseForm");
      this.$refs.AnamneseTable.carregar();
    },
    editar(anamnese) {
      this.anamnese_edit = Object.assign({}, anamnese);
      this.$bvModal.show("ModalAnamneseForm");
    },
  },
  async mounted() {},
  watch: {},
};
</script>

<style lang="scss"></style>
